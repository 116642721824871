.card-carousel1 {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card1 {
  border-radius: 8px;
  width: 90%;
}

.activa {
  position: relative!important;
  display: block;
  opacity: 1;
  z-index: 10;
  transform: scale(1) translateY(-45px);
  -webkit-transform: scale(1) translateY(-45px);
  -ms-transform: scale(1) translateY(-45px);
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
}
.anterior {
  position: absolute;
  display: none;
  opacity: 0;
  transform: scale(1) translateY(-80px);  
}
.siguiente {
  position: absolute;
  opacity: 0;
  transform: scale(1) translateY(-80px);
  transition: 2s step-start, transform .5s ease, opacity .5s ease;
  animation: flip 1s cubic-bezier(.1,.2,.32,.94) 1;
}
@keyframes flip {
  0%{
    transform: translate(0px,0px) scale(1);
    opacity: 1;
  }
  50%{
    transform: translate(-850px, -30px) scale(1);
    opacity: 1;
  }
  100%{
    transform: translate(0px,-70px) scale(1);
    opacity: 1;
  }
}
.inactiva {
  display: none;
  opacity: 0;
}

.efectoSobra {
  width: 90%;
}
.efectoSobraActiva {
  transform: scale(1) translateY(30px);
  z-index: -1;
  -moz-transition: 2s step-start, transform .5s ease, opacity .5s ease;
  -o-transition: 2s step-start, transform .5s ease, opacity .5s ease;
  -ms-transition: 2s step-start, transform .5s ease, opacity .5s ease;
  transition: 2s step-start, transform .5s ease, opacity .5s ease;
  animation: flip-sombra 5s cubic-bezier(.1,.2,.32,.94) 1;
}
@keyframes flip-sombra {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}