/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'ITC Avant Garde Gothic Std Extra Light';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Extra Light'), url('./fonts/ITCAvantGardeStdXLt.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Extra Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Extra Light Condensed'), url('./fonts/ITCAvantGardeStdXLtCn.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Extra Light Condensed Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Extra Light Condensed Oblique'), url('./fonts/ITCAvantGardeStdXLtCnObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Extra Light Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Extra Light Oblique'), url('./fonts/ITCAvantGardeStdXLtObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Book';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Book'), url('./fonts/ITCAvantGardeStdBk.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Book Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Book Condensed'), url('./fonts/ITCAvantGardeStdBkCn.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Book Condensed Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Book Condensed Oblique'), url('./fonts/ITCAvantGardeStdBkCnObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Book Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Book Oblique'), url('./fonts/ITCAvantGardeStdBkObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Medium';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Medium'), url('./fonts/ITCAvantGardeStdMd.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Medium Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Medium Condensed'), url('./fonts/ITCAvantGardeStdMdCn.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Medium Condensed Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Medium Condensed Oblique'), url('./fonts/ITCAvantGardeStdMdCnObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Medium Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Medium Oblique'), url('./fonts/ITCAvantGardeStdMdObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Demi';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Demi'), url('./fonts/ITCAvantGardeStdDemi.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Demi Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Demi Condensed'), url('./fonts/ITCAvantGardeStdDemiCn.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Demi Condensed Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Demi Condensed Oblique'), url('./fonts/ITCAvantGardeStdDemiCnObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Demi Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Demi Oblique'), url('./fonts/ITCAvantGardeStdDemiObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Bold';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Bold'), url('./fonts/ITCAvantGardeStdBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Bold Condensed'), url('./fonts/ITCAvantGardeStdBoldCn.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Bold Condensed Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Bold Condensed Oblique'), url('./fonts/ITCAvantGardeStdBoldCnObl.woff') format('woff');
  }
  

  @font-face {
  font-family: 'ITC Avant Garde Gothic Std Bold Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Bold Oblique'), url('./fonts/ITCAvantGardeStdBoldObl.woff') format('woff');
  }